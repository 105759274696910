<template>
  <div class="container">
    <bread-crumbs :currentpage="currentpage.name" />

    <div class="row">
      <div class="col">
        <h1>Mijn account</h1>
      </div>
    </div>

    <p>Op de account pagina vind u uw account gegevens en reserveringen</p>

    <div class="account-nav">
      <router-link
        v-for="page in pages"
        :key="page.name"
        :class="{
          btn: true,
          'btn-primary': $route.path === page.path,
          'btn-secondary': $route.path !== page.path,
          'btn-sm': true
        }"
        :to="page.path"
        >{{ page.name }}</router-link
      >
    </div>
    <hr />

    <div v-if="alertInfoMessage" class="alert alert-danger">
      {{ alertInfoMessage }}
    </div>
    <div>
      <router-view name="account" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Account',
  metaInfo: {
    title: `Mijn account`
  },
  data() {
    return {
      pages: [
        {
          name: 'Mijn gegevens',
          path: '/account/user'
        },
        {
          name: 'Organisatie',
          path: '/account/organisation'
        },
        {
          name: 'Contactpersonen',
          path: '/account/contact'
        },
        {
          name: 'Mijn reserveringen',
          path: '/account/reservation'
        },
        {
          name: 'Mijn artikelen',
          path: '/account/customer-item'
        },
        {
          name: 'Mijn serienummers',
          path: '/account/customer-serial'
        },
        {
          name: 'Artikelen in huur',
          path: '/account/item'
        },
        {
          name: 'Serienummers in huur',
          path: '/account/serial'
        },
        {
          name: 'Services',
          path: '/account/service'
        }
      ]
    }
  },
  computed: {
    currentpage() {
      return this.pages.find(page => page.path === this.$route.path)
    },
    user: function() {
      return this.$store.getters.user
    },
    alertInfoMessage: function() {
      if (this.$route.query.message) {
        this.notify({ message: this.$route.query.message })
        return
      }
      if (this.$route.query.infoMessage) return this.$route.query.infoMessage
      return null
    }
  },
  created: function() {
    if (this.$route.params.loggedIn) {
      this.notify({ message: 'Succesvol ingelogd' })
    }
    if (this.$route.params.message) {
      this.notify({ message: this.$route.parma.message })
    }
  },

  methods: {
    notify({ message }) {
      this.$bvToast.toast(message, {
        autoHideDelay: 5000,
        appendToast: false,
        title: 'success!',
        toaster: 'b-toaster-bottom-right'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.account-nav a {
  margin: 3px;
}
</style>
